import remoteConfig from '@react-native-firebase/remote-config';

const CACHE_EXPIRY = 60 * 60 * 1000;
const {
  INSIDER_SMART_RECOMMENDATION_STATUS,
  INSIDER_SMART_RECOMMENDATION_DATA,
} = require('~config/default.env').default;

const DEFAULTS = {
  [INSIDER_SMART_RECOMMENDATION_STATUS]: false,
  [INSIDER_SMART_RECOMMENDATION_DATA]: '[]',
};

let cachedConfig = null;
let lastFetchTime = null;

/*
 * Fetches remote config values and caches them for an hour.
 * If the cache is not expired, it returns the cached values.
 * If the fetch fails, it returns the cached values or the default values.
 * @returns {Object} - { configStatus: boolean, configData: string }
 * @throws {Error} - If remote config fetch fails and no cached values are available.
 */
export const getRemoteConfig = async () => {
  const now = Date.now();

  if (cachedConfig && lastFetchTime && now - lastFetchTime < CACHE_EXPIRY) {
    return cachedConfig;
  }

  try {
    await remoteConfig().setDefaults(DEFAULTS);
    await remoteConfig().fetchAndActivate();

    const configStatus = remoteConfig()
      .getValue(INSIDER_SMART_RECOMMENDATION_STATUS)
      .asBoolean();
    const configData = remoteConfig()
      .getValue(INSIDER_SMART_RECOMMENDATION_DATA)
      .asString();

    cachedConfig = { configStatus, configData };
    lastFetchTime = now;

    return cachedConfig;
  } catch (error) {
    console.error('Remote config fetch failed:', error);
    return cachedConfig || { configStatus: false, configData: '[]' };
  }
};

/*
 * Clears the remote config cache.
 */
export const clearRemoteConfigCache = () => {
  cachedConfig = null;
  lastFetchTime = null;
};
